@charset "UTF-8";

@import "_ress.scss";

/* =================================
 Common
================================= */
/* mixun */

@mixin clearfix {
  zoom: 1;
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

$breakpoints: (
    'tb': 'screen and (max-width: 979px)',
    'sp': 'screen and (max-width: 767px)',
    'hover': 'screen and (min-width: 769px)',
) !default;

@mixin mq($breakpoint: sp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

/* font */
@font-face {
	font-family: "Noto Sans Japanese";
	font-style: normal;
	font-weight: 400;
    src: local('NotoSansJP-Regular.otf'), url('../fonts/NotoSansCJKjp-Regular.woff') format('woff'), url('../fonts/NotoSansCJKjp-Regular.otf') format('opentype');
}
@font-face {
	font-family: "Noto Sans Japanese";
	font-style: normal;
	font-weight: 500;
    src: url('../fonts/NotoSansCJKjp-Medium.woff') format('woff'), url('../fonts/NotoSansCJKjp-Medium.otf') format('opentype');
}
@font-face {
	font-family: "Noto Sans Japanese";
	font-style: normal;
	font-weight: 700;
    src: url('../fonts/NotoSansCJKjp-Bold.woff') format('woff'), url('../fonts/NotoSansCJKjp-Bold.otf') format('opentype');
}

/* btn */
.mdl {
    &-button {
        position: relative;
        display: block;
        text-transform: uppercase;
        overflow: hidden;
        outline: none;
        cursor: pointer;
        text-decoration: none;
        text-align: center;
        vertical-align: middle;
        -webkit-tap-highlight-color: transparent;
        -webkit-tap-highlight-color: rgba(255,255,255,0);
        &::-moz-focus-inner {
            border:0
        }
        &__ripple-container {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            overflow: hidden
        }
    }
    &-ripple {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        background: #fff;
        border-radius: 50%;
        pointer-events: none;
        transform: translate(-50%, -50%);
        overflow: hidden;
        &.is-animating {
            transition: transform .3s cubic-bezier(0, 0, .2, 1), width .3s cubic-bezier(0, 0, .2, 1), height .3s cubic-bezier(0, 0, .2, 1), opacity .6s cubic-bezier(0, 0, .2, 1);
        }
        &.is-visible {
            opacity: .3
        }
    }
}
.btn-web {
    color: #fff;
    font-size: 13px;
    font-family: 'Roboto', sans-serif;
    line-height: 23px;
    padding: 0 10px;
    background-color: #ff812d;
    @include mq() {
        padding: 10px 0;
    }
    @include mq(hover) {
        &:hover {
            background-color: #ff944c;
        }
    }
    &__link-icon {
        width: 14px;
        height: 14px;
        margin: 4px 5px 3px 0;
        vertical-align: top;
        use {
            fill: #fff;
        }
    }
}
.btn {
    width: 270px;
    @include mq() {
        width: 100%;
    }
    a:focus,
    button:focus{
        outline: none;
    }
}
.btn-rotation {
    display: block;
    position: relative;
    color: #fff;
    text-align: center;
    text-decoration: none;
    width: 100%;
    height: 60px;
    & > span {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        line-height: 56px;
        width: 100%;
        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            right: 20px;
            width: 10px;
            height: 10px;
            transform: rotate(45deg);
        }
        &:before {
            margin: -5px 0 0; 
            background: #fff;
        }
        &:after {
            margin: -5px 3px 0 0; 
        }
    }
    & > span:nth-child(1) {
        border: #fff solid 2px;
        background: #2c2b34;
        transform: rotateY(0deg);
        transform-origin: 50% 50% -135px;
        z-index: 1;
        animation:contactBtn01-out .3s ease 0.0s forwards;
        &:after {
            background: #2c2b34;
        }
    }
    & > span:nth-child(2) {
        border: #ff812d solid 2px;
        background: #ff812d;
        transform: rotateY(-90deg);
        transform-origin: 50% 50% -135px;
        z-index: 0;
        animation:contactBtn02-out .3s ease 0.0s forwards;
        @include mq() {
            display: none;
        }
        &:after {
            background: #ff812d;
        }
    }
    @include mq(hover) {
        perspective: 300px;
        &:hover {
            & > span:nth-child(1) {
                animation:contactBtn01-hover .3s ease 0.0s forwards;
            }
            & > span:nth-child(2) {
                animation:contactBtn02-hover .3s ease 0.0s forwards;
            }
        }
    }            
}
.btn-advance {
    & > span:nth-child(1) {
        color: #2c2b34;
        border: #2c2b34 solid 2px;
        background: #fff;
        &:before {
            background: #2c2b34;
        }
        &:after {
            background: #fff;
        }
    }
}
.btn-submit {
    & > span:nth-child(1) {
        color: #ff812d;
        border: #ff812d solid 2px;
        background: #fff;
        &:before {
            background: #ff812d;
        }
        &:after {
            background: #fff;
        }
    }
}
.btn-return {
    & > span {
        &:before,
        &:after {
            right: auto;
            left: 20px;
        }
        &:before {
            margin: -5px 0 0; 
            background: #fff;
        }
        &:after {
            margin: -5px 0 0 3px; 
        }
    }
    & > span:nth-child(1) {
        color: #2c2b34;
        border: #2c2b34 solid 2px;
        background: #fff;
        animation:contactBtn03-out .3s ease 0.0s forwards;
        &:before {
            background: #2c2b34;
        }
        &:after {
            background: #fff;
        }
    }
    & > span:nth-child(2) {
        transform: rotateY(90deg);
        animation:contactBtn04-out .3s ease 0.0s forwards;
    }
    @include mq(hover) {
        &:hover {
            & > span:nth-child(1) {
                animation:contactBtn03-hover .3s ease 0.0s forwards;
            }
            & > span:nth-child(2) {
                animation:contactBtn04-hover .3s ease 0.0s forwards;
            }
        }
    }
}

/* keyframes */
@keyframes fadeInPage {
    0% {
        width: 100%;
    }
    100% {
        width: 0%;
    }
}
@keyframes fadeInRush {
    0% {
        opacity: 0;
        transform: translateX(500px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}
@keyframes fadeInLine {
    0% {
        width: 0;
        transform: translateX(-100px);
    }
    100% {
        width: 100%;
        transform: translateX(0px);
    }
}
@keyframes fadeInMater {
    0% {
        opacity: 0;
        margin-right: -1500px;
    }
    100% {
        opacity: 1;
        margin-right: 0px;
    }
}
@keyframes contactBtn01-hover {
    0% {
        z-index: 1;
        transform: rotateY(0deg);
    }
    50% {
        z-index: 0;
    }
    100% {
        z-index: 0;
        transform: rotateY(90deg);
    }
}
@keyframes contactBtn02-hover {
    0% {
        z-index: 0;
        transform: rotateY(-90deg);
    }
    50% {
        z-index: 1;
    }
    100% {
        z-index: 1;
        transform: rotateY(0deg);
    }
}
@keyframes contactBtn03-hover {
    0% {
        z-index: 1;
        transform: rotateY(0deg);
    }
    50% {
        z-index: 0;
    }
    100% {
        z-index: 0;
        transform: rotateY(-90deg);
    }
}
@keyframes contactBtn04-hover {
    0% {
        z-index: 0;
        transform: rotateY(90deg);
    }
    50% {
        z-index: 1;
    }
    100% {
        z-index: 1;
        transform: rotateY(0deg);
    }
}
@keyframes contactBtn01-out {
    0% {
        z-index: 0;
        transform: rotateY(90deg);
    }
    50% {
        z-index: 1;
    }
    100% {
        z-index: 1;
        transform: rotateY(0deg);
    }
}
@keyframes contactBtn02-out {
    0% {
        z-index: 1;
        transform: rotateY(0deg);
    }
    50% {
        z-index: 0;
    }
    100% {
        z-index: 0;
        transform: rotateY(-90deg);
    }
} 
@keyframes contactBtn03-out {
    0% {
        z-index: 0;
        transform: rotateY(-90deg);
    }
    50% {
        z-index: 1;
    }
    100% {
        z-index: 1;
        transform: rotateY(0deg);
    }
}
@keyframes contactBtn04-out {
    0% {
        z-index: 1;
        transform: rotateY(0deg);
    }
    50% {
        z-index: 0;
    }
    100% {
        z-index: 0;
        transform: rotateY(90deg);
    }
}
@keyframes qaCategoryBefore-hover {
    0% {
        margin-left: -8px;
    }
    45% {
        opacity: 1;
        margin-left: 22px;
    }
    46% {
        opacity: 0;
    }
    54% {
        opacity: 0;
    }
    55% {
        opacity: 1;
        margin-left: -38px;
    }
    100% {
        margin-left: -8px;
    }
}
@keyframes qaCategoryAfter-hover {
    0% {
        margin-left: -11px;
    }
    45% {
        opacity: 1;
        margin-left: 19px;
    }
    46% {
        opacity: 0;
    }
    54% {
        opacity: 0;
    }
    55% {
        opacity: 1;
        margin-left: -41px;
    }
    100% {
        margin-left: -11px;
    }
}
/* svg icon */
.svg-icon {
  position: absolute;
  width: 0;
  height: 0; 
}

/* ---------------------------------
 html
--------------------------------- */
body {
    position: relative;
    color: #2c2b34;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.75;
    &:before {
        content: "";
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100vh;
        background: #2c2b34;
    }
}
.mdl-js body {
    &:before {
        animation:fadeInPage 0.25s ease 0s forwards;
    }
}
a {
    color: #ff812d;
    @include mq(hover) {
        &:hover {
            text-decoration: none;
        }
    }
}
img {
    vertical-align: top;
    max-width: 100%;
}
img[src$=".svg"] {
    width: 100%;
}
li {
    list-style: none;
}

/* =================================
 header
================================= */
.page-header {
    position: relative;
    z-index: 1;
    height: 60px;
	background-color: #2c2b34;
    @include mq() {
        height: 40px;
    }
    h1 > a {
        display: block;
        position: absolute;
        top: 0;
        left: 20px;
        width: 337px;
        height: 0;
        padding: 60px 0 0;
        background: url(../images/sitelogo.svg) no-repeat left center;
        background-size: 100% auto;
        overflow: hidden;
        @include mq() {
            width: 180px;
            left: 15px;
            padding: 40px 0 0;
        }
    }
    &__navi {
        position: absolute;
        top: 0;
        right: 0;
    }
    &__list {
        @include clearfix;
        li {
            float: left;
            font-size: 15px;
        }
    }
    &__qa-btn,
    &__contact-btn {
        display: block;
        position: relative;
        color: #fff;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        line-height: 60px;
        text-decoration: none;
        transform-origin: 0% 100%;
        transform:skewX(-15deg);
        padding: 0 20px;
        @include mq() {
            line-height: 40px;
            padding: 0 10px;
        }
        & > * {
            display: inline-block;
            transform-origin: 50% 50%;
            transform:skewX(15deg);
        }
    }
    &__qa-btn {
        background: #3e3e47;
        @include mq(hover) {
            &:hover {
                background: #4b4b52;
            }
        }
    }
    &__contact-btn {
        background: #ff812d;
        @include mq() {
            span {
                display: none;
            }
        }
        @include mq(hover) {
            &:hover {
                background: #ff944c;
            }
        }
    }
    &__contact-icon {
        width: 24px;
        height: 24px;
        margin: 18px 10px 18px 0;
        vertical-align: top;
        @include mq {
            margin: 8px 0;
        }
        use {
            fill: #fff;
        }
    }
}

/* =================================
 footer
================================= */
.page-footer {
    height: 150px;
    padding:  70px 0;
    &__copy {
        font-size: 10px;
        font-family: 'Roboto', sans-serif;
        line-height: 1;
        text-align: center;
    }
}

/* =================================
 main
================================= */
.page-main {
    overflow: hidden;
}
/* ---------------------------------
 main visual
--------------------------------- */
.main-visual {
    position: relative;
    min-height: 300px;
    padding: 0 20px;
    background: #ff812d url(../images/bg_main_01.png) repeat-x left center;
    @include mq() {
        padding: 0 15px;
        background: #ff812d url(../images/bg_main_01_sp.png) repeat-x left center;
        background-size: 1px auto;
    }
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 0%;
        height: 100%;
        background: url(../images/bg_main_02.png) repeat-x left center;
        .mdl-js & {
            animation:fadeInLine 0.3s ease-in 0.65s forwards;
        }
        @include mq() {
            background: url(../images/bg_main_02_sp.png) repeat-x left center;
            background-size: 1px auto;
        }
    }
    &__text-area {
        position: relative;
        z-index: 1;
        max-width: 1260px;
        padding: 176px 0 180px;
        margin: 0 auto;
        @include mq(tb) {
            padding: 90px 0 140px;
        }
        @include mq() {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding: 25px 0 233px;
        }
    }
    &__catch-copy {
        color: #fff;
        font-size: 25px;
        line-height: 1;
        letter-spacing: 0.05em;
        opacity: 0;
        .mdl-js & {
            animation:fadeInRush 0.3s ease-out 0.25s forwards;
        }
        @include mq() {
            flex-basis: auto;
            font-size: 14px;
            text-align: center;
        }
        &--italic {
            display: block;
            font-style: italic;
            font-weight: 700;
            margin: 20px 0 0;
            @include mq() {
                line-height: 1.5;
                text-align: right;
                margin: 10px 0 0;
            }
        }
        &--big {
            font-size: 90px;
            letter-spacing: 0;
            @include mq(tb) {
                font-size: 70px;
            }
            @include mq() {
                display: block;
                font-size: 40px;
                white-space: nowrap;
            }
        }
    }
    &__credit {
        color: #fff;
        font-size: 15px;
        font-family: 'Roboto', sans-serif;
        line-height: 1;
        margin: 95px 0 0;
        @include mq(tb) {
            margin: 50px 0 0;
        }
        @include mq() {
            flex-basis: 100%;
            font-size: 12px;
            text-align: center;
            margin: 20px 0 0;
        }
    }
    &__image {
        position: absolute;
        top: 50%;
        right: 0;
        width: 800 / 1440 * 100 * 1%;
        max-width: 800px;
        margin: -40px 0 0;
        transform: translate((240 / 800 * 100 * 1%), -50%);
        opacity: 0;
        .mdl-js & {
            animation:fadeInMater 0.75s ease 0.25s forwards;
        }
        @include mq(tb) {
            margin: 0;
        }
        @include mq() {
            top: auto;
            right: 50%;
            bottom: -20px;
            width: 225px;
            transform: translateX(50%);
        }
    }
    &__line {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        &-01 {
            width: 524px;
            margin: -255px 0 0 -320px;
            .mdl-js & span {
                animation:fadeInLine 0.3s ease-in-out 0.25s forwards;
            }
            @include mq() {
                margin: -65px 0 0 230px;
            }
        }
        &-02 {
            width: 854px;
            margin: -240px 0 0 -260px;
            .mdl-js & span {
                animation:fadeInLine 0.3s ease-in-out 0.35s forwards;
            }
            @include mq() {
                margin: 5px 0 0 -340px;
            }
        }
        &-03 {
            width: 356px;
            margin: -155px 0 0 -80px;
            .mdl-js & span {
                animation:fadeInLine 0.3s ease-in-out 0.45s forwards;
            }
            @include mq() {
                margin: 35px 0 0 250px;
            }
        }
        &-04 {
            width: 1050px;
            margin: -140px 0 0 -350px;
            .mdl-js & span {
                animation:fadeInLine 0.3s ease-in-out 0.35s forwards;
            }
            @include mq() {
                margin: 85px 0 0 -580px;
            }
        }
        &-05 {
            width: 497px;
            margin: 50px 0 0 -390px;
            .mdl-js & span {
                animation:fadeInLine 0.3s ease-in-out 0.55s forwards;
            }
            @include mq() {
                margin: 95px 0 0 -375px;
            }
        }
        &-06 {
            width: 764px;
            margin: 65px 0 0 -410px;
            .mdl-js & span {
                animation:fadeInLine 0.3s ease-in-out 0.45s forwards;
            }
            @include mq() {
                margin: 180px 0 0 435px;
            }
        }
        &-07 {
            width: 674px;
            margin: 115px 0 0 40px;
            .mdl-js & span {
                animation:fadeInLine 0.3s ease-in-out 0.35s forwards;
            }
            @include mq() {
                margin: 185px 0 0 385px;
            }
        }
        &-08 {
            width: 823px;
            margin: 190px 0 0 -280px;
            .mdl-js & span {
                animation:fadeInLine 0.3s ease-in-out 0.55s forwards;
            }
            @include mq() {
                display: none;
            }
        }
        &-09 {
            width: 867px;
            margin: 190px 0 0 -280px;
            .mdl-js & span {
                animation:fadeInLine 0.3s ease-in-out 0.45s forwards;
            }
            @include mq() {
                display: none;
            }
        }
        span {
            display: block;
            width: 0%;
            height: 100%;
            background: #fff;
        }
    }
}

/* ---------------------------------
 intro
--------------------------------- */
.intro {
    padding: 0 20px;
    background: #2c2b34 url(../images/bg_intro.png) repeat left top;
    @include mq() {
        padding: 0 15px;
    }
    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1260px;
        padding: 60px 0;
        margin: 0 auto;
        @include mq(tb) {
            display: block;
        }
        @include mq() {
            padding: 30px 0;
        }
    }
    &__text-area {
        flex-basis: 580 / 1260 * 100 * 1%;
    }
    &__title {
        color: #ffffff;
        font-size: 40px;
        font-weight: 700;
        line-height: 1.5;
        @include mq() {
            font-size: 20px;
        }
    }
    &__text {
        color: #ffffff;
        font-size: 16px;
        margin: 1.75em -20px 0 0;
        @include mq(tb) {
            margin: 1.75em 0;
        }
        @include mq() {
            font-size: 14px;
        }
    }
    &__image {
        flex-basis: 680 / 1260 * 100 * 1%;
        max-width: 680px;
        margin: 0 auto;
    }
}

/* ---------------------------------
 summary
--------------------------------- */
.summary {
    &__text-area {
        display: flex;
        justify-content: center;
        padding: 35px 20px;
        @include mq() {
            padding: 20px 15px;
        }
    }
    &__text {
        position: relative;
        font-size: 30px;
        font-weight: 500;
        line-height: 1.5;
        padding: 0 0 0 130px;
        @include mq() {
            font-size: 15px;
            padding: 0 0 65px 0;
        }
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 91px;
            height: 100px;
            background: url(../images/eccubelogo.svg) no-repeat center center;
            background-size: 100% auto;
            @include mq() {
                width: 50px;
                height: 55px;
                top: auto;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        span {
            color: #ff812d;
            font-size: 50px;
            font-weight: 700;
            @include mq() {
                font-size: 25px;
            }
        }
    }
}
.author {
    padding: 0 20px;
    background-color: #eeeeee;
    @include mq() {
        padding: 0 15px;
    }
    &__content {
        max-width: 480px;
        padding: 40px 0;
        margin: 0 auto;
        @include clearfix;
        @include mq() {
            padding: 20px 0;
        }
    }
    &__image {
        position: relative;
        width: 70 / 480 *100 * 1%;
        float: left;
        @include mq() {
            width: 70px;
            margin: 0 auto 10px;
            float: none;
        }
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: -40px;
            left: 20px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 15px 0 15px;
            border-color: #ffffff transparent transparent transparent;
            @include mq() {
                top: -30px;
            }
        }
    }
    &__text-area {
        width: 380 / 480 *100 * 1%;
        float: right;
        @include mq() {
            width: auto;
            float: none;
        }
    }
    &__name {
        font-size: 20px;
        @include mq() {
            text-align: center;
        }
    }
    &__text {
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        line-height: 1.5;
        margin: 10px 0 0;
        @include mq() {
            font-size: 10px;
        }
        a {
            display: block;
        }
    }
    .notes {
        font-size: 10px;
        max-width: 1260px;
        margin: 0 auto;
        text-align: right;
        line-height: 25px;
    }
}

/* ---------------------------------
 contact
--------------------------------- */
.contact {
    color: #ffffff;
    line-height: 1.5;
    padding: 0 20px;
    background-color: #2c2b34;
    @include mq() {
        padding: 0 15px;
    }
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        max-width: 1260px;
        margin: 0 auto;
        @include mq() {
            display: block;
            padding: 20px 0 0;
        }
        &:before {
            content: "";
            display: block;
            position: absolute;
            z-index: -1;
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
            background-color: #ff812d;
            transform-origin:0 100%;
            transform:skewX(-15deg);
            @include mq() {
                margin: 0 0 0 15px;
                transform-origin: 100% 0;
            }
        }
    }
    &__title {
        font-size: 30px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-style: italic;
        padding: 0 40px 0 0;
        @include mq() {
            font-size: 20px;
        }
    }
    &__content {
        flex-basis: 1020px;
        display: flex;
        justify-content: space-between;
        padding: 60px 0;
        @include mq(tb) {
            display: block;
            padding: 35px 0;
        }
        @include mq() {
            padding: 20px 0;
        }
    }
    &__text-area {
        flex-basis: 580px;
    }
    &__text {
        font-size: 18px;
        padding: 0 0 40px;
        @include mq() {
            font-size: 15px;
            padding: 0 0 30px;
        }
    }
    .company {
        padding: 20px 0 0;
        border-top: #fff solid 1px;
        &__title {
            font-size: 14px;
            font-weight: 500;
            margin: 0 0 10px;
            @include mq() {
                margin: 0 0 5px;
            }
        }
        address {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-style: normal;
            @include mq(tb) {
                justify-content: flex-start;
            }
            @include mq() {
                display: block;
            }
        }
        &__name {
            font-family: 'Roboto', 'Noto Sans Japanese', sans-serif;
            padding: 0 20px 0 0;
            @include mq(tb) {
                padding: 0 40px 0 0;
            }
            @include mq() {
                padding: 0 0 20px;
            }
        }
        &__address {
            padding: 0 20px 0 0;
            @include mq(tb) {
                padding: 0 40px 0 0;
            }
            @include mq() {
                padding: 0 0 20px;
            }
        }
        &__link {
            flex-basis: 74px;
            flex-shrink: 0;
        }
    }
    &__link {
        flex-basis: 420px;
        padding: 0 0 0 40px;
        @include mq(tb) {
            padding: 20px 0 0;
            margin: 30px 0 0;
            border-top: #fff solid 1px;
        }
        dt {
            font-size: 20px;
            @include mq() {
                font-size: 15px;
            }
        }
        &-icon {
            width: 24px;
            height: 24px;
            vertical-align: top;
            margin: 3px 10px 3px 0;
            @include mq() {
                margin: 0 5px 0 0;
            }
            use {
                fill: #fff;
            }
        }
        dd {
            margin: 20px 0 0;
        }
    }
}

/* ---------------------------------
 page content
--------------------------------- */
.page-content {
    padding: 60px 20px 0;
    @include mq() {
        padding: 30px 15px 0;
    }
    &__title {
        color: #ff812d;
        font-size: 40px;
        font-weight: 700;
        line-height: 1.5;
        text-align: center;
        @include mq() {
            font-size: 20px;
        }
    }
    &__description {
        line-height: 1.5;
        text-align: center;
        margin: 20px 0 0;
        @include mq() {
            font-size: 14px;
            margin: 10px 0 0;
        }
    }
    &__list-area {
        padding: 0 0 40px;
    }
    &__list {
        padding: 30px 0 0;
        @include mq() {
            padding: 15px 0 0;
        }
        li {
            margin: 10px 0 0;
            p {
                position: relative;
                font-size: 20px;
                font-weight: 500;
                line-height: 1.5;
                @include mq() {
                    font-size: 15px;
                }
            }
            span {
                display: inline-block;
                position: absolute;
                color: #fff;
                line-height: 35px;
                width: 50px;
                text-align: center;
                background-color: #ff812d;
                @include mq() {
                    line-height: 25px;
                    width: 30px;
                }
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-style: solid;
                }
            }
        }
    }
    &-01 {
        .page-content__list {
            display: flex;
            justify-content: space-around;
            max-width: 1260px;
            margin: 0 auto;
            @include mq() {
                flex-wrap: wrap;
            }
            li {
                flex-basis: 300px;
                margin: 10px 10px 0;
                border: #2c2b34 solid 2px;
                @include mq() {
                    flex-basis: 47%;
                    margin: 10px 0 0;
                }
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
                figure {
                    background-color: #f4f4f5;
                }
                p {
                    text-align: center;
                    padding: 20px 0;
                    @include mq() {
                        padding: 10px 0;
                    }
                }
                span {
                    top: -20px;
                    left: 50%;
                    font-family: 'Roboto', sans-serif;
                    transform: translateX(-50%);
                    @include mq() {
                        top: -18px;
                    }
                    &:after {
                        bottom: -5px;
                        left: 50%;
                        margin: 0 0 0 -5px;
                        border-width: 5px 5px 0 5px;
                        border-color: #ff812d transparent transparent transparent;
                    }
                }
            }
        }
        .lead {
            text-align: center;
            padding: 20px 0 80px;
            margin: 0 -20px;
            background: #ff812d url(../images/dot.svg) no-repeat center bottom 4px;
            background-size: 4px auto;
            @include mq() {
                padding: 10px 0 70px;
            }
            &__text {
                position: relative;
                color: #fff;
                font-size: 40px;
                line-height: 1.5;
                @include mq() {
                    font-size: 20px;
                }
                &:before,
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: -32px;
                    left: 50%;
                    width: 20px;
                    height: 20px;
                    transform: rotate(45deg);
                    @include mq() {
                        bottom: -22px;
                    }
                }
                &:before {
                    margin: 0 0 0 -10px; 
                    background: #fff;
                }
                &:after {
                    margin: 0 0 4px -10px; 
                    background: #ff812d;
                }
            }
        }
    }
    &-02 {
        position: relative;
        z-index: 1;
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 35px;
            left: 50%;
            z-index: -1;
            width: 500px;
            height: 365px;
            margin: 0 0 0 250px;
            background: url(../images/gear.svg) no-repeat center center;
            background-size: 100% auto;
            @include mq(tb) {
                display: none;
            }
        }
        .page-content__list-area {
            display: flex;
            justify-content: center;
        }
        .page-content__list {
            li {
                padding: 2px 0 3px;
                @include mq() {
                    padding: 0;
                }
                p {
                    padding: 0 0 0 65px;
                    @include mq() {
                        padding: 0 0 0 45px;
                    }
                }
                span {
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    &:after {
                        top: 50%;
                        right: -5px;
                        margin: -5px 0 0 0;
                        border-width: 5px 0 5px 5px;
                        border-color: transparent transparent transparent #ff812d;
                    }
                }
            }
        }
        .example {
            padding: 40px 20px 80px;
            margin: 0 -20px;
            background: #eee url(../images/bg_example.png) repeat left top;
            @include mq(tb) {
                padding: 40px 20px 140px;
            }
            @include mq() {
                padding: 20px 15px 140px;
                margin: 0 -15px;
            }
            &__content {
                max-width: 1260px;
                margin: 0 auto;
            }
            &__title {
                font-size: 27px;
                font-weight: 700;
                line-height: 45px;
                padding: 0 0 0 60px;
                background: url(../images/eccubelogo.svg) no-repeat left center;
                background-size: 40px auto;
                @include mq() {
                    font-size: 18px;
                    line-height: 30px;
                    padding: 0 0 0 40px;
                    background-size: 25px auto;
                }
            }
            &__text {
                margin: 20px 0 0;
                @include mq() {
                    font-size: 14px;
                    margin: 10px 0 0;
                }
            }
            &__note-area {
                position: relative;
                max-width: 750px;
                margin: 40px auto 0;
                border: #959599 solid 1px;
                background: #fff;
                @include mq() {
                    margin: 20px auto 0;
                }
                &:before,
                &:after{
                    content: "";
                    display: block;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 20px 0 0 20px;
                    @include mq(tb) {
                        margin: 0 -10px 0 0;
                        border-width: 20px 10px 0 10px;
                    }
                }
                &:before {
                    bottom: -1px;
                    right: -20px;
                    border-color: transparent transparent transparent #959599;
                    @include mq(tb) {
                        bottom: -21px;
                        right: 50%;
                        border-color: #959599 transparent transparent transparent;
                    }
                }
                &:after {
                    bottom: 0;
                    right: -18px;
                    border-color: transparent transparent transparent #fff;
                    @include mq(tb) {
                        bottom: -19px;
                        right: 50%;
                        border-color: #fff transparent transparent transparent;
                    }
                }
            }
            &__note-wrap {
                position: relative;
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: -11px;
                    right: -100px;
                    width: 70px;
                    height: 70px;
                    background: #fff url(../images/icon_yuh.svg) no-repeat center center;
                    background-size: 70% auto;
                    border-radius: 35px;
                    @include mq(tb) {
                        bottom: -101px;
                        right: 50%;
                        margin: 0 -35px 0 0;
                    }
                }
            }
            &__note {
                position: relative;
                color: #79797e;
                font-size: 20px;
                line-height: 1.5;
                padding: 20px 80px;
                @include mq() {
                    font-size: 16px;
                    padding: 10px 40px;
                }
                &:before,
                &:after{
                    content: "“";
                    display: block;
                    position: absolute;
                    color: #959599;
                    font-size: 120px;
                    line-height: 130px;
                    width: 80px;
                    text-align: center;
                    @include mq() {
                        font-size: 60px;
                        line-height: 1;
                        width: 40px;
                    }
                }
                &:before {
                    top: 0;
                    left: 0;
                }
                &:after {
                    right: 0;
                    bottom: 0;
                    transform: rotate(180deg);
                }
            }
        }
        .concrete-example {
            position: relative;
            padding: 40px;
            margin: 40px 0 0;
            background: #fff;
            @include mq() {
                padding: 15px;
            }
            &__title {
                position: absolute;
                top: -20px;
                left: 40px;
                color: #fff;
                font-size: 18px;
                font-weight: 500;
                line-height: 40px;
                padding: 0 15px;
                background-color: #575563;
                @include mq() {
                    left: 15px;
                    font-size: 15px;
                    line-height: 30px;
                }
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    right: -10px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 20px 0 0 10px;
                    border-color: transparent transparent transparent #1a191e;
                }
            }
            &__table--scroll {
                overflow: auto;
                    &::-webkit-scrollbar{
                        height: 5px;
                    }
                    &::-webkit-scrollbar-track{
                        background: #F1F1F1;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #BCBCBC;
                    }
            }
            &__table {
                width: 100%;
                border-collapse: collapse;
                border-bottom: #959599 solid 1px;
                border-left: #959599 solid 1px;
                th,
                td {
                    padding: 0;
                    border-top: #959599 solid 1px;
                    border-right: #959599 solid 1px;
                    vertical-align: top;
                    @include mq() {
                        font-size: 12px;
                    }
                }
                th {
                    font-weight: 500;
                    min-width: 410px;
                    padding: 10px 20px;
                    background-color: #f4f4f5;
                    @include mq(tb) {
                        width: 50%;
                        min-width: inherit;
                    }
                    @include mq() {
                        padding: 10px;
                    }
                }
                &-content {
                    display: table;
                    width: 100%;
                    @include mq(tb) {
                        display: block;
                    }
                }
            }
            &__image {
                display: table-cell;
                vertical-align: middle;
                text-align: center;
                margin: auto;
                width: 242px;
                padding: 20px;
                border-right: #959599 solid 1px;
                @include mq(tb) {
                    display: block;
                    width: auto;
                    border-right: none;
                }
                @include mq() {
                    padding: 5px;
                }
                span {
                    display: inline-block;
                    border: #d7d7db solid 1px;
                }
            }
            &__text {
                display: table-cell;
                vertical-align: middle;
                padding: 20px;
                @include mq(tb) {
                    display: block;
                    padding: 0 20px 20px;
                }
                @include mq() {
                    padding: 0 10px 10px;
                }
            }
        }
        .demo-site {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 40px 0 0;
            @include mq() {
                margin: 20px 0 0;
                display: block;
            }
            &__image {
                flex-basis: 630px;
            }
            &__text-area {
                flex-basis: 1180px - 630;
                padding: 0 0 0 40px;
                @include mq() {
                    padding: 15px 0 0;
                }
            }
            &__text {
                margin: 0 0 20px;
                @include mq() {
                    font-size: 14px;
                    padding: 0 0 10px;
                    margin: 0;
                }
            }
            &__button {
                @include mq() {
                    margin: 20px 0;
                }
                a {
                    display: inline-block;
                    color: #fff;
                    font-size: 18px;
                    line-height: 42px;
                    min-width: 250px;
                    background-color: #ff812d;
                    @include mq() {
                        width: 100%;
                        min-width: inherit;
                    }
                    @include mq(hover) {
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                    &:before,
                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: 20px;
                        width: 10px;
                        height: 10px;
                        transform: rotate(45deg);
                    }
                    &:before {
                        margin: -5px 0 0; 
                        background: #fff;
                    }
                    &:after {
                        margin: -5px 3px 0 0; 
                        background: #ff812d;
                    }
                }
            }
        }
    }
    &-03 {
        .page-content-top,
        .page-content-bottom {
            max-width: 1260px;
            margin: 0 auto;
        }
        .page-content-top {
            padding: 10px 0 0;
        }
        .page-content__plan {
            margin: 30px 0 0;
            border: #2c2b34 solid 2px;
            @include mq() {
                margin: 15px 0 0;
            }
            &-title {
                display: flex;
                align-items: stretch;
                font-weight: 500;
                border-bottom: #2c2b34 solid 2px;
                &-number {
                    display: block;
                    font-family: 'Roboto', sans-serif;
                    font-size: 10px;
                    font-weight: 500;
                    text-align: center;
                    line-height: 1;
                    padding: 11px 15px 9px;
                    span {
                        display: block;
                        font-size: 24px;
                        margin: 2px 0 0;
                        @include mq() {
                            font-size: 20px;
                        }
                    }
                }
                &-text {
                    display: inherit;
                    align-items: center;
                    font-size: 20px;
                    line-height: 1.5;
                    padding: 10px 20px;
                    border-left: #2c2b34 solid 2px;
                    @include mq(tb) {
                        padding: 10px 15px;
                    }
                    @include mq() {
                        font-size: 15px;
                    }
                }
            }
            &-speed {
                display: flex;
                align-items: stretch;
                justify-content: space-between;
                max-width: 1260px;
                margin: 0 auto;
                @include mq() {
                    display: block;
                }
                &-image,
                &-text,
                &-price {
                    display: inherit;
                    align-items: center;
                }
                &-image {
                    flex-basis: 389px;
                    padding: 50px 0;
                    border-right: #2c2b34 solid 2px;
                    @include mq() {
                        border-right: none;
                        border-bottom: #2c2b34 solid 2px;
                    }
                }
                &-text {
                    flex-basis: 651px;
                    padding: 40px;
                    border-right: #2c2b34 solid 2px;
                    @include mq(tb) {
                        padding: 15px 15px 20px;
                    }
                    @include mq() {
                        font-size: 14px;
                        border-right: none;
                        border-bottom: #2c2b34 solid 2px;
                    }
                }
                &-price {
                    justify-content: center;
                    flex-basis: 214px;
                    font-family: 'Roboto', sans-serif;
                    font-size: 30px;
                    font-weight: 500;
                    line-height: 1;
                    padding: 0 20px;
                    background: #f4f4f5;
                    @include mq(tb) {
                        padding: 0 15px;
                    }
                    @include mq() {
                        display: block;
                        font-size: 25px;
                        text-align: center;
                        padding: 15px;
                    }
                    span {
                        font-size: 18px;
                        margin: 7px 0.25em 0 0;
                        @include mq() {
                            margin: 0 0.25em 0 0;
                        }
                    }
                }
            }
            &-other {
                &-text {
                    padding: 20px 20px 30px;
                    border-bottom: #2c2b34 solid 2px;
                    @include mq(tb) {
                        padding: 15px 15px 20px;
                    }
                    @include mq() {
                        font-size: 14px;
                    }
                }
                &-price {
                    font-family: 'Roboto', sans-serif;
                    font-size: 30px;
                    font-weight: 500;
                    text-align: center;
                    line-height: 1;
                    padding: 20px 20px;
                    background: #f4f4f5;
                    @include mq(tb) {
                        padding: 15px;
                    }
                    @include mq() {
                        font-size: 25px;
                    }
                    span {
                        font-size: 18px;
                        margin: 0 0.25em 0 0;
                    }
                }
            }
        }
        .page-content-bottom {
            display: flex;
            justify-content: space-between;
            padding: 0 0 80px;
            @include mq(tb) {
                display: block;
            }
            .page-content__plan {
                flex-basis: 50%;
                &:first-child {
                    margin-right: 15px;
                    @include mq(tb) {
                        margin-right: 0;
                    }
                }
                &:last-child {
                    margin-left: 15px;
                    @include mq(tb) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
    &__text {
        max-width: 600px;
        margin: 0 auto;
        @include mq() {
            font-size: 14px;
        }
    }
    &__point {
        font-size: 30px;
        line-height: 1.5;
        text-align: center;
        margin: 40px 0 80px;
        @include mq() {
            font-size: 20px;
            margin: 20px 0 40px;
        }
        span {
            display: inline-block;
            position: relative;
            z-index: 1;
            &:after {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: -1;
                width: 100%;
                height: 100 / 3 * 1%;
                background-color: #fdd000;
            }
        }
    }
}

/* ---------------------------------
 page title
--------------------------------- */
.page-title {
    position: relative;
    color: #fff;
    text-align: center;
    background: #ff812d;
    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        width: 200%;
        height: 100%;
        background: rgba(255,255,255,0.10)
    }
    &:before {
        left: 50%;
        margin: 0 0 0 -410px;
        transform-origin: 0 100%;
        transform:skewX(-85deg);
    }
    &:after {
        right: 50%;
        margin: 0 50px 0 0;
        transform-origin: 100% 100%;
        transform:skewX(75deg);
    }
    h2 {
        display: block;
        position: relative;
        padding: 60px 20px;
        @include mq() {
            padding: 30px 15px;
        }
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            width: 200%;
            height: 100%;
            margin: 0 0 0 140px;
            background: rgba(255,255,255,0.10);
            transform-origin: 0 100%;
            transform:skewX(-45deg);
            @include mq() {
                margin: 0 0 0 40px;
            }
        }
    }
    &__main-text {
        display: block;
        font-size: 50px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-style: italic;
        line-height: 1.2;
        letter-spacing: 0.05em;
        @include mq() {
            font-size: 40px;
        }
    }
    &__sub-text {
        display: block;
        font-size: 12px;
        line-height: 1;
        margin: 10px 0 0;
        font-weight: 400;
    }
}

/* ---------------------------------
 page company
--------------------------------- */
.page-company {
    position: relative;
    color: #fff;
    line-height: 1.5;
    padding: 0 20px;
    background-color: #2c2b34;
    @include mq() {
        padding: 0 15px;
    }
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 50%;
        width: 100%;
        height: 100%;
        margin: 0 250px 0 0;
        background: #ff812d;
        transform-origin: 100% 50%;
        transform:skewX(-15deg);
        @include mq() {
            margin: 0 190px 0 0;
        }
    }
    &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        padding: 40px 0;
        margin: 0 auto;
        @include mq() {
            display: block;
            padding: 20px 0;
        }
    }
    &__title {
        font-size: 20px;
        font-weight: 700;
        padding: 0 50px 0 0;
        @include mq() {
            font-size: 18px;
            padding: 0;
        }
    }
    &__contents {
        display: flex;
        padding: 0 0 0 50px;
        font-size: 14px;
        @include mq() {
            display: block;
            padding: 20px 0 0;
        }
    }
    &__name {
        padding: 0 40px 0 0;
        @include mq() {
            padding: 0 0 15px;
        }
    }
    &__address {
        padding: 0 40px 0 0;
        @include mq() {
            padding: 0 0 15px;
        }
    }
}

/* ---------------------------------
 contact page
--------------------------------- */
.contact-page {
    &-contents {
        max-width: 640px;
        padding: 0 20px;
        margin: 0 auto;
        @include mq() {
            padding: 0 15px;
        }
        &__form {
            padding: 60px 0 100px;
            @include mq() {
                padding: 30px 0 60px;
            }
            &-description {
                padding: 0 0 20px;
                @include mq() {
                    font-size: 14px;
                    padding: 0;
                }
            }
            &-parts {
                position: relative;
                padding: 34px 0 0;
                margin: 30px 0 0;
                textarea {
                    height: 29px;
                    transition-duration: .2s;
                    transition-timing-function: cubic-bezier(.4,0,.2,1);
                }
                &.is-focused,
                &.is-dirty {
                    textarea {
                        height: 300px;
                    }
                }
            }
            &-input {
                position: relative;
                width: 100%;
                vertical-align: top;
                .is-focused & {
                    outline: none;
                }
            }
            &-label {
                position: absolute;
                top: 34px;
                bottom: 0;
                left: 0;
                z-index: -1;
                width: 100%;
                color: #96959a;
                transition-duration: .2s;
                transition-timing-function: cubic-bezier(.4,0,.2,1);
                &:before,
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: -1px;
                    left:0;
                }
                &:before {
                    width: 100%;
                    height: 1px;
                    background: #96959a;
                }
                &:after {
                    width: 0;
                    height: 2px;
                    background: #ff812d;
                    visibility: hidden;
                    transition-duration: .2s;
                    transition-timing-function: cubic-bezier(.4,0,.2,1);
                }
                .is-focused &,
                .is-dirty & {
                    top: 0;
                    color: #ff812d;
                    font-size: 12px;
                    line-height: 24px;
                    &:after {
                        width: 100%;
                        visibility: visible;
                    }
                }
            }
            &-btn {
                display: flex;
                justify-content: space-around;
                margin: 80px 0 0;
                @include mq() {
                    display: block;
                    margin: 0;
                    padding: 40px 0 0;
                }
                .btn {
                    @include mq() {
                        margin: 15px 0 0;
                    }
                }
            }
        }
        &__confirm-parts {
            position: relative;
            &:before {
                content: "";
                display: block;
                position: absolute;
                bottom: -1px;
                left:0;
                width: 100%;
                height: 1px;
                background: #96959a;
            }
            margin: 30px 0 0;
            dt {
                color: #96959a;
                font-size: 12px;
                line-height: 24px;
            }
            dd {
                margin: 10px 0 0;
                @include mq() {
                    font-size: 14px;
                }
            }
        }
        &__complete-parts {
            padding: 60px 0 40px;
            @include mq() {
                padding: 0;
            }
            dt {
                font-size: 30px;
                line-height: 1.5;
                text-align: center;
                margin: 0 0 40px;
                @include mq() {
                    text-align: left;
                    font-size: 20px;
                    margin: 0 0 20px;
                }
            }
            dd {
                @include mq(){
                    font-size: 14px;
                }
            }
        }
    }
}

/* ---------------------------------
 qa page
--------------------------------- */
.qa-form {
    padding: 25px 20px;
    background: #f4f4f5;
    &__box {
        position: relative;
        max-width: 600px;
        height: 50px;
        margin: 0 auto;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.2);
    }
    &__input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 11px 50px 11px 10px;
        &:focus {
            outline: none;
        }
    }
    &__submit {
        position: absolute;
        top: 0;
        right: 0;
        padding: 13px;
        line-height: 1;
        &:focus {
            outline: none;
        }
        &-icon {
            width: 24px;
            height: 24px;
            vertical-align: top;
            use {
                fill: #ff812d;
            }
        }
    }
}
.qa-page {
    &-contents {
        max-width: 1300px;
        padding: 60px 20px 100px;
        margin: 0 auto;
        @include mq() {
            padding: 30px 15px 60px;
        }
        &__description {
            text-align: center;
            @include mq() {
                font-size: 14px;
            }
        }
        &__btn {
            margin: 80px 0 0;
            @include mq() {
                margin: 55px 0 0;
            }
            .btn {
                margin: 0 auto;
            }
        }
    }
}
.qa-category {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mq() {
        display: block;
        padding: 15px 0 0;
    }
    &-item {
        flex-basis: 50%;
        margin: 30px 0 0;
        @include mq() {
            margin: 15px 0 0;
        }
        a {
            color: #2c2b34;
            height: 100%;
            padding: 40px 20px 100px;
            border: solid 2px #2c2b34;
            @include mq(tb) {
                padding: 20px 15px 100px;
            }
            @include mq(hover) {
                &:hover {
                    .qa-category-item__view-more {
                        &:before {
                            animation:qaCategoryBefore-hover 0.5s ease-in 0s forwards;
                        }
                        &:after {
                            animation:qaCategoryAfter-hover 0.5s ease-in 0s forwards;
                        }
                    }
                }
            }
            .mdl-ripple {
                background: #ff812d;
            }
        }
        &:nth-child(odd) a {
            margin-right: 15px;
        }
        &:nth-child(even) a {
            margin-left: 15px;
        }
        @include mq() {
            &:nth-child(odd) a,
            &:nth-child(even) a {
                margin: 0;
            }
        }
        &__title {
            position: relative;
            padding: 85px 0 0;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 50%;
                width: 70px;
                height: 75px;
                background: url(../images/case.svg) no-repeat center center;
                background-size: contain;
                transform: translateX(-50%);
            }
            span {
                display: block;
            }
            &--sub {
                font-family: Roboto,sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 1;
                @include mq() {
                    font-size: 12px;
                }
            }
            &--main {
                font-size: 24px;
                line-height: 1.5;
                margin: 20px 0 0;
                @include mq() {
                    font-size: 18px;
                }
            }
        }
        &__view-more {
            position: absolute;
            bottom: 40px;
            left: 50%;
            width: 40px;
            height: 0;
            padding: 40px 0 0;
            background: #ff812d;
            overflow: hidden;
            border-radius: 100%;
            transform: translateX(-50%);
            @include mq(tb) {
                bottom: 30px;
            }
            &:before,
            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 12px;
                height: 12px;
                margin-top: -6px;
                transform: rotate(45deg);
            }
            &:before {
                margin-left: -8px;
                background: #fff;
            }
            &:after {
                margin-left: -11px;
                background: #ff812d;
            }
        }
    }
}
.qa-category-list {
    &__title {
        color: #ff812d;
        font-size: 40px;
        font-weight: 500;
        line-height: 1.5;
        padding: 0 0 40px;
        @include mq() {
            font-size: 20px;
            padding: 0 0 15px;
        }
    }
    &-item {
        margin: 20px 0 0;
        border: #2c2b34 solid 2px;
        @include mq() {
            margin: 15px 0 0;
        }
        &__title {
            display: flex;
            align-items: flex-start;
            font-size: 0;
            padding: 10px 40px;
            background: #f4f4f5;
            @include mq() {
                padding: 10px 15px;
            }
            span {
                display: block;
            }
            &-icon {
                display: block;
                position: relative;
                flex-shrink: 0;
                color: #fff;
                font-family: 'Roboto', sans-serif;
                font-size: 23px;
                font-weight: 500;
                text-align: center;
                line-height: 37px;
                width: 37px;
                margin: 0 20px 0 0;
                background: #ff812d;
                border-radius: 100%;
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    margin: 0 -2px -2px 0;
                    border-top: 10px solid #ff812d;
                    border-right: 5px solid transparent;
                    border-bottom: 0 solid transparent;
                    border-left: 5px solid transparent;
                    transform: rotate(-45deg);
                    transform-origin: center center;
                }
                @include mq() {
                    font-size: 18px;
                    margin: 0 15px 0 0;
                }
            }
            &-text {
                font-size: 18px;
                font-weight: 700;
                line-height: 1.5;
                margin: 5px 0;
                @include mq() {
                    font-size: 16px;
                }
            }
        }
        &__question {
            margin: 20px 40px;
            @include mq() {
                font-size: 14px;
                margin: 10px 15px 20px;
            }
        }
        &__btn {
            text-align: right;
            margin: 0 40px 20px;
            @include mq() {
                margin: 10px 15px 20px;
            }
            a {
                display: inline-block;
                color: #fff;
                line-height: 1;
                width: 100%;
                max-width: 250px;
                padding: 13px 10px;
                background: #ff812d;
                @include mq() {
                    max-width: none;
                }
                @include mq(hover) {
                    &:hover {
                        background-color: #ff944c;
                        &:after {
                            background-color: #ff944c;
                        }
                    }
                }
                &:before,
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    z-index: 0;
                    width: 10px;
                    height: 10px;
                    transform: rotate(45deg);
                }
                &:before {
                    margin: -5px 0 0; 
                    background: #fff;
                }
                &:after {
                    margin: -5px 3px 0 0; 
                    background: #ff812d;
                }
            }
        }
    }
}
.qa-category-detail {
    @include mq() {
        padding: 0 0 40px;
    }
    &__title {
        color: #ff812d;
        font-size: 40px;
        font-weight: 500;
        line-height: 1.5;
        padding: 0 0 40px;
        @include mq() {
            font-size: 20px;
            padding: 0 0 15px;
        }
    }
    pre {
        margin: 0 0 1.75em;
        border: 1px solid #dfdfdf;
        @include mq() {
            border-right: none;
            border-left: none;
            margin-right: -15px;
            margin-left: -15px;
        }
        ol {
            font-size: 14px;
            padding: 0 0 0 3.5em;
            @include mq() {
                font-size: 10px;
                padding: 0;
            }
        }
        li {
            padding: 0 1em;
            list-style: decimal;
            white-space: pre-wrap;
            @include mq() {
                list-style: none;
            }
            &.L1,
            &.L3,
            &.L5,
            &.L7,
            &.L9 {
                background: #f2f2f2;
            }
        }
    }
}
.qa-question,
.qa-answer {
    position: relative;
    &__title {
        display: inline-block;
        position: absolute;
        top: -18px;
        left: 40px;
        color: #fff;
        font-family: 'Roboto', sans-serif;
        font-size: 23px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0.05em;
        text-align: center;
        width: 100%;
        max-width: 140px;
        border-radius: 18px;
        @include mq() {
            top: -14px;
            left: 15px;
            font-size: 18px;
            line-height: 28px;
            border-radius: 14px;
        }
    }
    &__contents {
        position: relative;
        padding: 40px;
        @include mq() {
            padding: 30px 15px 15px;
        }
        p {
            margin: 0 0 1.75em;
            @include mq() {
                font-size: 14px;
            }
        }
    }
}
.qa-question {
    margin: 20px 0 0;
    border: #2c2b34 solid 2px;
    &__title {
        background: #2c2b34;
    }
}
.qa-answer {
    margin: 30px 0 0;
    border: #ff812d solid 2px;
    &__title {
        background: #ff812d;
    }
    &:before,
    &:after {
        position: absolute;
        content: "";
        display: block;
        right: 0;
        bottom: 0;
    }
    &:before {
        border-top: solid 0 transparent;
        border-right: solid 40px #ff812d;
        border-bottom: solid 30px transparent;
        border-left: solid 0 transparent;
        margin: 0px 150px -30px 0;
        @include mq() {
            border-right: solid 20px #ff812d;
            border-bottom: solid 15px transparent;
            margin: 0px 80px -15px 0;
        }
    }
    &:after {
        border-top: solid 0 transparent;
        border-right: solid 40px #fff;
        border-bottom: solid 30px transparent;
        border-left: solid 0 transparent;
        margin: 0px 152px -26px 0;
        @include mq() {
            border-right: solid 20px #fff;
            border-bottom: solid 15px transparent;
            margin: 0px 82px -11px 0;
        }
    }
    &__contents {
        &:after {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            bottom: -160px;
            width: 119px;
            height: 136px;
            background: url(../images/icon_yuh.svg) no-repeat center center;
            background-size: contain;
            @include mq() {
                bottom: -90px;
                width: 70px;
                height: 80px;
            }
        }
    }
}
